/**
 * Fixed header component
 */
/* eslint-disable */
import React from 'react';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';

// components
import HeaderMenu from "./HeaderMenu";
import SideBarMenuFixedHeader from '../sidebar/SideBarMenuFixedHeader';
import AppConfig from '../../../constants/AppConfig';
import Cart from "./Cart";
import SearchBoxV2 from './SearchBoxV2';
import Logout from "./Logout";

    class FixedHeader extends React.Component {

        render() {

            var style = {
                color: '#fff'
            }


            return (
                <div className="iron-fixed-header" style={{backgroundColor:'#fff', borderBottom:'1px solid #eee'}}>
                    <div className="container">

                        <Grid container spacing={0} >

                            <Grid item xs={8} sm={6} md={3} lg={3} xl={3} className="d-flex justify-content-start align-items-center" >

                                

                                <div className="iron-app-logo ">
                                    <Link to="/">
                                        <img src={AppConfig.AppLogo} alt="header-logo-openlab" width="150px" />
                                    </Link>
                                </div>
                            </Grid>

                            <Grid item md={6} lg={7} xl={7} className=" d-flex justify-content-start align-items-center" >
                                
                            </Grid>

                            <Grid item xs={4} sm={6} md={3} lg={2} xl={2} className="d-flex justify-content-end align-items-center" >
                                <div className="iron-header-widgets d-flex justify-content-end align-items-center ">
                                    <Cart />
                                    <Logout style={style} />
                                </div>
                            </Grid>

                            {/*
                            <HeaderMenu navLinks={this.props.navLinks} />
                             */}
                        </Grid>
                    </div>
                </div>
   );
            }
}

export default FixedHeader;