import React from 'react';
import { Grid, Chip, Typography } from '@material-ui/core/';
import CurrencyIcon from '../global/currency/CurrencyIcon';
import { axiosInstance } from '../../util/axiosInstance';
import { formatCurrency } from '../../helpers/currencyHelper';


class AdditionalDiscount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        }
    }

    async componentDidMount() {
       
    }

    render() {

        const haveCoupon = this.props.haveAdditionalDiscount;

        const percentDiscount = this.props.discount;

        var finalPriceWithDiscount = 0;

        var leftTotal = (this.props.discountFixedAmount * 2) - this.props.totalPrice;

        var apply = false;

        if (haveCoupon && percentDiscount > 0) {
            apply = true;
        }

        if (haveCoupon && leftTotal <= 0) {
            apply = true;
        }

        var labelChipDiscount = percentDiscount + "% OFF";

        if (this.props.discountFixedAmount != 0) {

            labelChipDiscount = formatCurrency(this.props.discountFixedAmount) + " OFF"

            if (this.props.totalPrice >= this.props.discountFixedAmount) {
                finalPriceWithDiscount = this.props.totalPrice - this.props.discountFixedAmount;
                finalPriceWithDiscount = finalPriceWithDiscount.toFixed(2)
            }
        }
        else {
            finalPriceWithDiscount = this.props.totalPriceFirstOrder;
		}


        return (

            <Grid container spacing={1}>
                <Grid item xs={7}>
                    <h5 style={{ color: '#0074bc', fontSize: '1.0rem', float: 'left' }}>
                        Su pago final
                        </h5>
                </Grid>
                <Grid item xs={5}>
                    <h5 style={apply ? { color: '#0074bc', fontSize: '1.0rem', float: 'right', fontWeight: '500', textDecoration: 'line-through' } : { color: '#0074bc', fontSize: '1.0rem', float: 'right', fontWeight: '500' } }>
                        {formatCurrency(this.props.totalPrice)}
                    </h5>
                </Grid>

                { apply &&

                    <Grid item xs={12}>
                        <Grid container spacing={1}
                            justify="space-between"
                            alignItems="center">
                            <Grid item>
                                <Chip className="w-100" style={{ backgroundColor: '#e43e6e', color: '#FFF' }} label={labelChipDiscount} />
                            </Grid>
                            <Grid item>
                                <Typography style={{ color: '#e43e6e', fontSize: '1.3rem', fontWeight: '800' }}>
                                    {formatCurrency(finalPriceWithDiscount)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                }

                { haveCoupon &&

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <h5 style={{ color: '#e43e6e', fontSize: '1.0rem', float: 'center' }}>

                                {apply && leftTotal <= 0 ?
                                    <>Cupón aplicado</>
                                    :
                                <div>
                                    <center>
                                        Suma {formatCurrency(leftTotal)}  para aplicar el cupón
                                        </center>
                                </div>

                                }
                            </h5>
                        </Grid>

                    </Grid>
                }



            </Grid>

        );






  //      if (haveCoupon && leftTotal <= 0) {

  //          return (
  //              <div>

  //                  <Grid container spacing={1}>
  //                      <Grid item xs={7}>
  //                          <h5 style={{ color: '#0074bc', fontSize: '1.0rem', float: 'left' }}>
  //                              Su pago final
  //                          </h5>
  //                      </Grid>
  //                      <Grid item xs={5}>
  //                          <h5 style={{ color: '#0074bc', fontSize: '1.0rem', float: 'right', fontWeight: '500', textDecoration: 'line-through' }}>                               
  //                              {formatCurrency(this.props.totalPrice)}
  //                          </h5>
  //                      </Grid>
  //                  </Grid>


               



  //                  <br />

  //              </div>
  //          );
  //      }
  //      else {
  //          return (
  //              <Grid container spacing={1}>
  //                  <Grid item xs={7}>
  //                      <h5 style={{ color: '#0074bc', fontSize: '1.0rem', float: 'left' }}>
  //                          Su pago final
  //                      </h5>
  //                  </Grid>
  //                  <Grid item xs={5}>
  //                      <h5 style={{ color: '#0074bc', fontSize: '1.0rem', float: 'right', fontWeight: '500' }}>
  //                          {formatCurrency(this.props.totalPrice)}
  //                      </h5>
  //                  </Grid>
  //              </Grid>);
		//}
    };
}

export default AdditionalDiscount;