/**
 * cart list item
 */
/* eslint-disable */
import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import IconButton from "@material-ui/core/IconButton";
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';

//connect to store
import { connect } from "react-redux";

// global component
import CurrencyIcon from '../../global/currency/CurrencyIcon';
import ConfirmationDialog from '../../global/confirmation-popup';

import axios from 'axios';
import { getSubTotalPrice, getTotalPrice, getTotalSave, getTotalExtraSave, getTotalPriceFirstOrder } from "../../../helpers";


import UserContext from '../../../userContext';

import parse from 'html-react-parser';
import AdditionalDiscount from '../../widgets/AdditionalDiscount';

//action
import { removeProductItem, initializeCart } from '../../../actions/action';

class Cart extends React.Component {

   constructor(props) {
      super(props);
      this.confirmationDialog = React.createRef();
      this.state = {
         anchorEl: null,
      };
   }
    static contextType = UserContext

    async componentDidMount() {
        const shoppingCartResponse = await axios.get('/api/ShoppingCart/GetCart');
        this.props.initializeCart(shoppingCartResponse.data);
    }

   //Define function for open dropdown
   handleClick = event => {
      this.setState({
         anchorEl: event.currentTarget,
      });
   };

   //Define function for close dropdown
   handleClose = () => {
      this.setState({
         anchorEl: null,
      });
   };

   //Function to delete product from cart
   onDeleteCartItem(cartItem) {
      this.cartItem = cartItem;
      this.confirmationDialog.current.openDialog();
   }

   //Function for delete cart product
   deleteCartItem(popupResponse) {
      if (popupResponse) {
         this.props.removeProductItem(this.cartItem);
         this.cartItem = ""
      }
      this.setState(
         {
            anchorEl: null,
         }
      )
   }

   //get url
   getUrl(url) {
      //console.log("url", url.split('/')[0])
      return url.split('/')[0];
   }

   render() {

      const { anchorEl } = this.state;
      const open = Boolean(anchorEl);
      const { cart } = this.props;


       var { user } = this.context;


       if (user.isAuth === false) {
           return null;
       }
       else {
           return (
               <div className="iron-cart-wrap">
                   <IconButton
                       color="inherit"
                       aria-owns={open ? 'simple-popper' : null}
                       aria-haspopup="true"
                       variant="contained"
                       onClick={this.handleClick}
                       className="icon-btn mr-10"
                       aria-label="Cart"
                   >
                       {cart && cart.length > 0 ?
                           (
                               <Badge
                                   badgeContent={cart.length}
                                   color="secondary"
                                   className="badge-active"
                               >
                                   <i className="material-icons">shopping_cart</i>
                               </Badge>
                           )
                           :
                           (
                               <i className="material-icons">shopping_cart</i>
                           )
                       }
                   </IconButton>
                   <Popover
                       id="simple-popper"
                       open={open}
                       anchorEl={anchorEl}
                       onClose={this.handleClose}
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'center',
                       }}
                       transformOrigin={{
                           vertical: 'top',
                           horizontal: 'center',
                       }}
                   >
                       <div>
                           {(cart && cart.length > 0) ?
                               (
                                   <Fragment>
                                       <ul className="iron-cart-list-wrap mb-0">
                                           {cart && cart.map((cartItem, index) => {
                                               return (
                                                   <li key={index} className="cart-menu-item p-10 mb-0">
                                                       <div className="d-flex iron-cart-post">
                                                           <div className="cart-thumb">


                                                               {
                                                                   this.getUrl(cartItem.image) === 'https:' ?
                                                                       <img
                                                                           src={cartItem.image}
                                                                           alt='product-thumbnail'
                                                                       />
                                                                       :
                                                                       <img
                                                                           src={cartItem.image}
                                                                           alt='product-thumbnail'
                                                                       />
                                                               }


                                                           </div>
                                                           <div className=" cart-content-wrap d-flex justify-content-start align-items-center">
                                                               <div className="cart-content" >
                                                                   <h6 className="mb-5 text-truncate">{parse(cartItem.name)}</h6>
                                                                   <span>
                                                                       Cantidad: {cartItem.quantity}
                                                                   </span>
                                                               </div>
                                                               <div className="cart-edit-action d-flex justify-content-end align-items-center">
                                                                   <Button
                                                                       className="icon-btn button mr-5"
                                                                       onClick={() => this.onDeleteCartItem(cartItem)}
                                                                   >
                                                                       <i className="material-icons">delete</i>
                                                                   </Button>

                                                               </div>
                                                           </div>
                                                       </div>
                                                   </li>
                                               )
                                           })
                                           }
                                       </ul>
                                       {/*
                                       <div className="py-15 px-10">
                                           <Grid container>
                                          
                                               <Grid item xs={6}>
                                                   <h5 style={{ fontSize: '1.0rem', float: 'left', marginBottom: '0.1rem' }}>
                                                       Subtotal
                                                </h5>
                                               </Grid>
                                               <Grid item xs={6}>
                                                   <h5 style={{ fontSize: '1.0em', float: 'right', marginBottom: '0.1rem' }}>
                                                       <CurrencyIcon /> {getSubTotalPrice()}
                                                   </h5>
                                               </Grid>

                                               <Grid item xs={6}>
                                                   <h5 style={{ fontSize: '1.0rem', float: 'left', marginBottom: '0.1rem', color: '#ff7f27' }}>
                                                       Descuento
                                                </h5>
                                               </Grid>
                                               <Grid item xs={6}>
                                                   <h5 style={{ fontSize: '1.0em', float: 'right', marginBottom: '0.1rem', color: '#ff7f27' }}>
                                                       <CurrencyIcon />  {getTotalSave()}
                                                   </h5>
                                               </Grid>

                                               <Grid item xs={6}>
                                                   <h5 style={{ color: '#339933', fontSize: '1.0rem', float: 'left', marginBottom: '0.1rem' }}>
                                                       Ahorro extra
                                                </h5>
                                               </Grid>
                                               <Grid item xs={6}>
                                                   <h5 style={{ color: '#339933', fontSize: '1.0em', float: 'right', marginBottom: '0.1rem' }}>
                                                       <CurrencyIcon /> {getTotalExtraSave()}
                                                   </h5>
                                               </Grid>




                                           </Grid>

                                       </div>
                                       */}
                                       <div className=" py-15 px-10">
                                           <Grid
                                               container
                                               justify="space-between"
                                               alignItems="center"
                                               style={{ marginTop: '15px', paddingTop: '3px', paddingBottom: '3px' }}>

                                               <Typography inline variant="body1" align="left" className={"MuiTypography--subheading"} variant={"h5"} color="default" style={{ fontWeight:'500', fontSize: '1.0em' }}>
                                                   Subtotal
                                               </Typography>

                                               <Typography inline variant="h5" color="default" align="right" style={{ fontWeight: '500',fontSize: '1.0rem' }}>
                                                   <CurrencyIcon /> {getSubTotalPrice()}
                                               </Typography>

                                           </Grid>
                                           <Grid
                                               container
                                               justify="space-between"
                                               alignItems="center"
                                               style={{ paddingTop: '3px', paddingBottom: '3px' }}>

                                               <Typography inline variant="h5" align="left" className={"MuiTypography--subheading"} variant={"caption"} style={{ fontWeight: '500',color:'#ff7f27', fontSize: '1.0em' }}>
                                                   Descuento
                                               </Typography>

                                               <Typography inline variant="body1" color="secondary" align="right" style={{ fontWeight: '500', fontSize: '1.0' }}>
                                                   <CurrencyIcon />  {getTotalSave()}
                                               </Typography>
                                           </Grid>

                                           <Grid
                                               container
                                               justify="space-between"
                                               alignItems="center"
                                               style={{ paddingTop: '3px', paddingBottom: '3px' }}>

                                               <Typography inline variant="h5" align="left" className={"MuiTypography--subheading"} variant={"caption"} color="extra-save" style={{ fontWeight: '500',color: '#339933', fontSize: '1.0em'  }}>
                                                    Ahorro extra
                                               </Typography>

                                               <Typography inline variant="h5" color="secondary" align="right" style={{ fontWeight: '500', color: '#339933', fontSize: '1.0rem' }}>
                                                   <CurrencyIcon /> {getTotalExtraSave()}
                                               </Typography>
                                           </Grid>

                                           <AdditionalDiscount totalPriceFirstOrder={getTotalPriceFirstOrder()} totalPrice={getTotalPrice()} />



                                       </div>
                                       <div className=" py-15 px-10">
                                           <Button onClick={this.handleClose} component={Link} to="/cart" className="button btn-active w-100 btn-rounded">
                                               Finalizar pedido
                                            </Button>
                                       </div>
                                   </Fragment>
                               )
                               :
                               (
                                   <div>
                                       <span className="text-capitalize text-14 dark-color d-block px-40 py-15">Carrito sin productos</span>
                                   </div>
                               )
                           }
                       </div>
                       <ConfirmationDialog
                           ref={this.confirmationDialog}
                           onConfirm={(res) => this.deleteCartItem(res)}
                       />
                   </Popover>
               </div>
           );
       }


   }
}

// map state to props
const mapStateToProps = ({ ecommerce }) => {
   const { cart } = ecommerce;
   return { cart };
}

export default connect(mapStateToProps, {
    initializeCart, 
   removeProductItem
})(Cart);

