module.exports = {

    "menu.Categorias": "Categorias",
    "menu.Ofertas": "Ofertas",
    "menu.Bebés": "Bebes",
    "menu.Bebes": "Bebes",
    "Bebés": "Bebes",
    "Belleza y cuidado personal": "Belleza y cuidado personal",
    "Perfumes y fragancias": "Perfumes y fragancias",
    "Cuidado del cabello": "Cuidado del cabello",
    "Cuidado oral": "Cuidado oral",
    "menu.Dermocosmetica": "Dermocosmetica",
    "menu.Dermocosmeticas": "Dermocosmetica",
    "menu.ReservaConReceta": "Reserva con receta",
    "menu.SanJoseSalud": "San Jose Salud",
    "menu.RRHH": "RRHH",
    "Dermocosmetica": "Dermocosmetica",


   "menu.adminPanel": "admin panel",
   "menu.Home": "Home",  
   "menu.HomeOne": "home uno ",
   "menu.HomeTwo": "home dos ",
   "menu.HomeThree": "home tres ",
   "menu.fashion": "Fashion",
   "menu.shop": "shop",
   "menu.gadgets": "gadgets",
   "menu.accessories": "accesorios",
   "menu.categories": "categorias",
   "menu.pages": "paginas",
   "menu.session": "sesion",
   "menu.contact us": " contactenos",
   "menu.men": "men",
   "menu.women": "women",
   "menu.t-shirts": "t-shirts",
   "menu.jeans": "jeans",
   "menu.shoes": "shoes",
   "menu.wallet": "wallet",
   "menu.westernWear": "western wear",
   "menu.ethnicWear": "ethnic wear",
   "menu.sportsWear": "sports wear",
   "menu.headPhones": "headPhones",
   "menu.laptop": "laptop",
   "menu.speaker": "speaker",
   "menu.watch": "watch",
   "menu.jewellery": "jewellery",
   "menu.belts": "belts",
   "menu.handBag": "handBag",
   "menu.productDetail": "detalle del producto",
   "menu.cart": "cart",
   "menu.checkout": "checkout",
   "menu.payment": "payment",
   "menu.blogDetail": "blog detail",
   "menu.userProfile": "Perfil de usuario",
   "menu.faq": "faq",
   "menu.404Page": "404 page",
   "menu.signIn": "sign in",
   "menu.register": "register",
   "menu.forgotPassword": "forgot password",
   "menu.thankYou": "thank you",
   "menu.aboutUs": "about us",
   "menu.termsAndConditions": "terms and conditions",
   "menu.privacyPolicy": "privacy policy",
   "footer.aboutUs": "Acerca de nosotros",
   "footer.termsAndCondition": "Términos y condiciones",
   "footer.PrivacyPolicy": "Políticas de privacidad",
   "footer.Faq": "Preguntas frecuentes",
   "footer.contactUs": "Contactenos",
   "footer.signIn": "sign in",
   "footer.register": "Registrar",
   "footer.forgetPassword": "forget password",
   "footer.thankYou": "Gracias",
   "footer.women": "women",
   "footer.men": "men",
   "footer.accessories": "accessories",
   "footer.gadgets": "gadgets",
   "footer.facebook": "Facebook",
   "footer.twitter": "Twitter",
   "footer.youtube": "Youtube",
   "footer.instagram": "Instagram",
    "footer.WhatsApp": "WhatsApp",
    "footer.googlePlus": "Google plus",


   "admin.reports": "reports",
   "admin.invoices": "invoices",
   "admin.products": "products",
   "admin.profile": "profile",
   "admin.productAdd": "agregar producto",
   "admin.goToHome": "ir al inicio"
}
