/**
 * locales data
 */
export const languages = [
    {
        locale: 'en',
        name: 'English',
        icon: 'en',
    },
    {
        locale: 'fr',
        name: 'French',
        icon: 'fr',
    }
    ,
    {
        locale: 'es',
        name: 'Espa�ol',
        icon: 'es',
    }
]