/**
 * English locale
 */
import esMessages from '../locales/es_AR';

const EsLang = {
    messages: {
        ...esMessages
    },
    locale: 'es-AR'
};
export default EsLang;