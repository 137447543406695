
export function formatCurrency(value, decimalScale = 2) {

    let ARSpesos = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    return (ARSpesos.format(value));
}

