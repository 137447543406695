import React from 'react';
import axios from 'axios';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { Link, withRouter } from 'react-router-dom';
import Popover from '@material-ui/core/Popover';

import Person from '@material-ui/icons/Person';

import { deleteToken, getToken } from '../../../helpers/auth-helper';
import Button from '@material-ui/core/Button';

//connect to store
import UserContext from '../../../userContext';

class Logout extends React.Component {
    constructor(props) {
        super(props);
    }
    static contextType = UserContext

    state = {
        anchorEl: null,
        result: null,
        isAuthenticated: false,
        menus: [
            {
                id: 1,
                title: 'Mis datos',
                icon: 'account_circle',
                path: '/account/profile'
            },

            //{
            //    id: 4,
            //    title: 'Salir',
            //    icon: 'power_settings_new',
            //    path: '/sign-in'
            //}
        ]
    };

    //define function for open dropdown
    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    //define function for close dropdown
    handleClose = (e) => {

        this.setState({ anchorEl: null });
    };

    handleCloseSignOut = (e) => {

        const { logoutUser } = this.context;

        this.setState({ anchorEl: null });
        deleteToken(e);
        logoutUser();

        this.props.history.push("/sign-in");
    }

    async componentDidMount() {
    }

    renderLoginOptions() {

        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className='iron-logout-wrap'>
                <Avatar
                    aria-owns={open ? 'menu-appbar' : null}
                    aria-haspopup="true"
                    onClick={this.handleMenu}
                    className="icon-btn"
                >
                </Avatar>


                <div>
                    <Menu
                        anchorEl={anchorEl}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                        open={open}
                        onClose={this.handleClose}
                        className="iron-dropdown"
                    >
                        {this.state.menus.map((menu, index) => (
                            <MenuItem
                                key={index}
                                onClick={this.handleClose}
                                to={menu.path}
                                component={Link}
                                title={menu.title}
                            >
                                <span className="mb-0">{menu.title}</span>
                            </MenuItem>
                        ))}

                        <MenuItem
                            onClick={this.handleCloseSignOut}
                        //   component={Link}
                        >
                            <span className="mb-0">Salir</span>
                        </MenuItem>
                    </Menu>
                </div>



            </div>
        );
    }

    renderLogoutOptions() {
        return (
            <div>
                <Link to="/sign-in" style={this.props.style}>
                    <Button color="primary">
                        Iniciá sesión
                    </Button>
                </Link>

                <Link to="/sign-up" style={this.props.style}>
                    <Button color="primary" className="btn-rounded" variant="contained" style={{ backgroundColor:'#EE0A0A', marginLeft:'4px'}}>
                        Registrate
                    </Button>
                </Link>
            </div>
        );
    }


    render() {

        var { user } = this.context;


        if (user.isAuth === false) {
            return (this.renderLogoutOptions())
        }
        else {
            return (this.renderLoginOptions())
        }
    }
}


export default withRouter(Logout);


