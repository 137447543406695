/* eslint-disable */
/**
 * Header menu component
 */
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classnames from "classnames";




import { FaWhatsapp   } from 'react-icons/fa';



class HeaderMenu extends React.Component {

    constructor(props) {
        super(props);    
    }

    


    render() {
        if (this.props.navLinks == undefined)
            return null;
         
        return (
            <div className="horizontal-menu">
                <ul className="d-inline-block iron-header-menu mb-0">

                    <li>
                        <Link to="/opcion-1">
                            Menu 1
                        </Link>
                    </li>

                    <li>
                        <Link to="/opcion-2">
                            Menu 2
                        </Link>
                    </li>

                    <li>
                        <a style={{ color: '#25d366' }} target="_blank" href="https://wa.me/5491141995417/?text=Hola%2C%20me%20comunico%20desde%20www.biosintex24hs.com.ar%20">
                            <FaWhatsapp style={{ fontSize: '25px' }} /> WhatsAps
                        </a>
                    </li>


                    {this.props.navLinks.map((navLink, index) => {


                        //tiene subitems
                        if (navLink.child_routes && navLink.child_routes != null) {
                            return (
                                <li key={index} className={classnames({ 'mega-menu': navLink.mega })}>
                                    <a href="#">
                                        {navLink.menu_title}
                                        

                                    </a>
                                    {(navLink.type && navLink.type === 'subMenu') ?
                                        <Fragment>
                                            {navLink.child_routes !== null &&
                                                <ul className="sub-menu mb-0">
                                                    {navLink.child_routes && navLink.child_routes.map((subNavLink, index) => (
                                                        <Fragment key={index}>

                                                            {subNavLink.child_routes !== null ?
                                                                <li >
                                                                    <a href="#" className="d-flex justify-content-between align-items-center">
                                                                        <IntlMessages id={subNavLink.menu_title} />
                                                                        <i className="material-icons">keyboard_arrow_right</i>
                                                                    </a>
                                                                    <ul className="sub-menu-child mb-0">
                                                                        {subNavLink.child_routes.map((subMenuItem, index) => (
                                                                            <li key={index}>
                                                                                <Link to={subMenuItem.path}>
                                                                                    <IntlMessages id={subMenuItem.menu_title} />
                                                                                </Link>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </li>
                                                                :
                                                                < li>
                                                                    <Link to={subNavLink.path}>
                                                                        {subNavLink.menu_title}
                                                                    </Link>
                                                                </li>
                                                            }

                                                        </Fragment>
                                                    ))}
                                                </ul>
                                            }
                                        </Fragment>
                                        :
                                        <Fragment>
                                            {navLink.child_routes !== null &&
                                                <ul className="sub-menu mb-0 d-flex">
                                                    {navLink.child_routes && Object.keys(navLink.child_routes).map((subNavLink, index) => (
                                                        <li key={index}>
                                                            <a href="#"><IntlMessages id={subNavLink} /></a>
                                                            <ul className="sub-menu mb-0">

                                                                {/*

                                                                {navLink.child_routes[subNavLink].map((megaMenuItem, index) => (
                                                                    <li key={index}>
                                                                        <Link to={megaMenuItem.path}>
                                                                            <IntlMessages id={megaMenuItem.menu_title} />
                                                                        </Link>
                                                                    </li>
                                                                ))}

                                                                */}

                                                            </ul>
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        </Fragment>
                                    }
                                </li>
                            )
                        }
                        if (navLink.menu_title === 'WhatsApp') {
                            return (<li key={index}>
                                <a style={{ color: '#25d366' }} target="_blank" href="https://wa.me/5491141995417/?text=Hola%2C%20me%20comunico%20desde%20www.biosintex24hs.com.ar%20">
                                   <FaWhatsapp style={{fontSize:'25px'}} /> {navLink.menu_title}
                                </a>
                            </li>)
                        } else {
                            return (
                                <li key={index}>
                                    <Link to={navLink.path}>
                                        {navLink.menu_title}
                                    </Link>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        );
    }
}

export default HeaderMenu;
