/**
 * Confirmation dialog component
*/
/* eslint-disable */
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Typography } from '@material-ui/core';


class ConfirmationDialog extends React.Component {
    state = {
        open: false,
    };

    //Define function for open confirmation dialog box
    openDialog() {
        this.setState({ open: true });
    };

    //Define function for close confirmation dialog box and callback for delete item 
    closeDialog(isTrue) {
        this.setState({ open: false });
        this.props.onConfirm(isTrue)
    };

    render() {

        return (
            <Dialog
                open={this.state.open}
                onClose={this.closeDialog.bind(this)}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    Eliminando...
                    </DialogTitle>
                <DialogContent className="p-20 text-center">
               <DialogContentText >
                        ¿Estás seguro de que deseas eliminar este producto?
               </DialogContentText>
            </DialogContent>
                <DialogActions className="px-20 pb-20 justify-content-right">
                    <Button onClick={() => this.closeDialog(true)}
                        className="mr-15 btn-rounded"
                        variant="outlined"
                    >
                        Eliminar
               </Button>
                    <Button onClick={() => this.closeDialog(false)} className="mr-15 btn-rounded" autoFocus variant="outlined">
                        Cancelar
               </Button>
                </DialogActions>
            </Dialog >
        );
    }
}

export default ConfirmationDialog;