/**
 * header search widget
 */
import React from 'react';
import Input from '@material-ui/core/Input';
import { withRouter } from 'react-router-dom';


class SearchBoxV2 extends React.Component {

    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.state = {
            inputText: '',
        };

    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            var inputText = this.state.inputText;

          

            this.setState({
                inputText: ''
            })

            this.props.history.push("/buscador/" + inputText);


        }
    }


    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }


    render() {
        return (
            <div className="search-form-v2 px-15" >
                <div className="input-wrap">
                        <Input
                        fullWidth
                        placeholder="¿Qué estás buscando?"
                        disableUnderline
                        name="inputText"
                        className="input-field"
                        onKeyDown={this.handleKeyDown}
                        value={this.state.inputText}
                        onChange={this.onChange}
                        />
                </div>
            </div>
        )
    }
}

export default withRouter(SearchBoxV2);
