/**
 * App Language Provider
 * Add more locales here
 */

//import enLang from './entries/en-US';
//import frLang from './entries/fr_FR';
import esLang from './entries/es-AR';

const AppLocale = {
    //en: enLang,
    //fr: frLang,
    es: esLang
};

export default AppLocale;
