/**
 * Helpers Method
 */
import { store } from "../index";

/**
 *  function to check product is exits in cart or not
 */
export function isProductExist(ID) {

   let exists = false
   let cart = store.getState().ecommerce.cart;    
   if (cart && cart.length > 0) {
       for (const cartItem of cart) {           

         if (cartItem.productID === ID) {
            exists = true
         }
      }
   }
   return exists;
}

export function getCartItem(ID) {

    let cartItemReturn = null;
    let cart = store.getState().ecommerce.cart;
    if (cart && cart.length > 0) {
        for (const cartItem of cart) {

            if (cartItem.productID === ID) {
                cartItemReturn = cartItem
            }
        }
    }
    return cartItemReturn;
}

/**
 *  function to check product is exits in wishlist or not
 */
export function productExitsInWishlist(ID) {
   let exist = false
   let wishlist = store.getState().ecommerce.wishlist;
   if (wishlist && wishlist.length > 0) {
      for (const item of wishlist) {
         if (item.productID === ID) {
            exist = true
         }
      }
   }
   return exist;
}

/**
 * Get subTotal Price
 */
export function getSubTotalPrice() {
   let subTotalPrice = 0;
    let cart = store.getState().ecommerce.cart;

   if (cart && cart.length > 0) {
      for (const cartItem of cart) {
         subTotalPrice += cartItem.subTotal;
      }
       return subTotalPrice.toFixed(2);
   }
}

/**
 * get Total Price
 */
export function getTotalPrice() {

    const subTotalPrice = getSubTotalPrice();
    const totalSave = getTotalSave();
    const totalExtraSave = getTotalExtraSave();

    const total = subTotalPrice - totalSave - totalExtraSave;

    return total.toFixed(2);
}

export function getTotalSave() {
    let totalSave = 0;
    let cart = store.getState().ecommerce.cart;


    if (cart && cart.length > 0) {
        for (const cartItem of cart) {
            totalSave += cartItem.save;
        }
        return totalSave.toFixed(2);
    }


}

export function getTotalExtraSave() {
    let totalExtraSave = 0;
    let cart = store.getState().ecommerce.cart;


    if (cart && cart.length > 0) {
        for (const cartItem of cart) {
            totalExtraSave += cartItem.extraSave;
        }
        return totalExtraSave.toFixed(2);
    }


}

export function getTotalPriceFirstOrder(couponDiscount) {

    const subTotalPrice = getSubTotalPrice();
    const totalSave = getTotalSave();
    const totalExtraSave = getTotalExtraSave();

    const total = subTotalPrice - totalSave - totalExtraSave;

    const totalPriceFirstOrder = total.toFixed(2) * ((100 - couponDiscount) / 100);

    return totalPriceFirstOrder.toFixed(2);
}

export function getOrderText() {
    let text = "";

    text = "https://wa.me/5491141995417/?text=Hola%2C%20me%20comunico%20desde%20www.biosintex24hs.com%20";

    return text;
}