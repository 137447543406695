/**
 * site header two component
 */
/* eslint-disable */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Grid from "@material-ui/core/Grid";
import { Link } from 'react-router-dom';
// components
import HeaderMenu from "./HeaderMenu";
import Cart from "./Cart";
import Logout from "./Logout";
import FixedHeader  from '../headers/FixedHeader';
import SidebarMenu from '../sidebar';
import AppConfig from '../../../constants/AppConfig';
import SearchBox from './SearchBox';
import SearchBoxV2 from './SearchBoxV2';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';

import axios from 'axios';
import { Hidden } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import UserContext from '../../../userContext';


class HeaderTwo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fixedHeader: false,
            navLinks: [],
        }    
    }

    static contextType = UserContext


    componentDidMount() {
       // this.getNavLinksData();

        window.addEventListener('scroll', this.hideBar);
    }



    UNSAFE_componentWillMount() {
       // this.getNavLinksData();

       window.addEventListener('scroll', this.hideBar);
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.hideBar);
   }

    getNavLinksData() {
        axios.get('/api/Home/GetHeaderMenu/').then(
            response => {
                this.setState({
                    navLinks: response.data,
                });
            });
    }
    

   //Function to show and hide fixed header
   hideBar = () => {
      const { fixedHeader } = this.state;
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      this.scrollTop >= 200 ?
         !fixedHeader && this.setState({ fixedHeader: true })
         :
         fixedHeader && this.setState({ fixedHeader: false });
   }


    render() {

        var style = {
            color: '#666'
        }
     
        var { user } = this.context;

 
  
        return (

         <div>
            <AppBar position="static" className={`iron-header-wrapper bg-white iron-header-v2 ${(this.state.fixedHeader) ? 'header-fixed' : ''}`}>
                    <div className="iron-header-top bg-white" style={{borderBottom: '1px solid #eee'}}>

                        <div className="container" style={{paddingLeft:'15px', paddingRight:'15px'}}>

                        <Grid container spacing={3} >

                            <Grid item xs={4} sm={3} md={3} lg={3} xl={3} className="d-flex justify-content-start align-items-center" >

                                    {/*
                                    <SidebarMenu navLinks={this.state.navLinks} />
                                    */}

                                    <div className="iron-app-logo text-md-center">
                                        <Link to="/">
                                            <img src={AppConfig.AppLogo} alt="header-logo-openlab" width="150px" />
                                        </Link>
                                    </div>
                            </Grid>

                            
                                <Grid item xs={3} sm={6} md={6} lg={6} xl={6} className=" d-flex justify-content-start align-items-center" >
                                    {/* <SearchBoxV2 />*/}
                                    <Hidden smDown>

                                        

                                    <h6 style={{ color: '#4d66b3', margin: '0px 24px 0px 0px', fontSize: '1.0rem' }}>
                                        <PhoneIcon style={{ marginRight: '6px', marginTop: '-3px' }} />
                                        0810-777-6327
                                    </h6>

                                        <a href="mailto:info@biosintex24hs.com.ar">
                                            <h6 style={{ color: '#4d66b3', margin: '0px' }}> <MailIcon style={{ marginRight: '6px', marginTop: '-3px' }} />
                                        info@biosintex24hs.com.ar
                                    </h6>
                                        </a>

                                     


                                    </Hidden>

                                </Grid>
                            

                            <Grid item xs={5} sm={3} md={3} lg={3} xl={3} className="d-flex justify-content-end align-items-center" >
                                <div className="iron-header-widgets d-flex justify-content-end align-items-center ">
                                    <Cart />
                                    <Logout style={style} />
                                </div>
                            </Grid>

                        </Grid>

                        </div>

                        {
                            user.isAuth ? null :

                                <a style={{ color: '#25d366' }} target="_blank" href={"https://wa.me/5491164202467/?text=Hola%2C%20me%20comunico%20desde%20www.biosintex24hs.com%20"}>

                                    <Fab color="primary" aria-label="WhatsApps"
                                        style={{
                                            color: "#FFF",
                                            backgroundColor: '#25D366',
                                            position: 'fixed',
                                            bottom: '2rem',
                                            right: '2rem',
                                            zIndex: '999'
                                        }}>
                                        <WhatsAppIcon />
                                    </Fab>

                                </a>
                        }
            </div>
       
                <FixedHeader navLinks={this.state.navLinks}/>
            </AppBar>
         </div>
      );
   }
}



export default HeaderTwo;

