/**
 * Main App
 */
import React, { Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from "react-redux";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import { Route, Switch, Router } from 'react-router-dom';

import axios from 'axios';


// css
import './lib/embryoCss.js';

// App locale
import AppLocale from './lang';

import HeaderTwo from "./components/layouts/headers/HeaderTwo";
import FooterOne from "./components/layouts/footers/FooterOne";



import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import { UserProvider  } from './userContext';
import { createBrowserHistory } from 'history';

import {

    AsyncHomePageTwoComponent,

    AsyncAboutUSComponent,
    AsyncCartComponent,
    AsyncFAQComponent,
    AsyncPrivacyPolicyComponent,
    AsyncTermAndConditionComponent,    
    AsyncProductDetail2Component,
    AsyncInvoiceComponent,
    AsyncSignInPageComponent,
    AsyncSignUpComponent,
    AsyncSignUpSmallComponent,
    AsyncForgetPasswordComponent,
    AsyncPageNotFoundComponent,

    AsyncProfileComponent,
    AsyncPasswordRecoveryComponent,
    AsyncReferredClientsComponent
} from './util/AsyncRoutes';


import { hideAlert } from "./actions/action";
import footerData from './assets/data/footerData';
import ThemeOptions from './components/ThemeOptions/ThemeOptions';
import { getToken } from './helpers/auth-helper';


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            categories: [],
            user: '',        
        };

        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
    }

    async componentDidMount() {



        await axios.get('/api/auth/GetAppVersion')
            .then(response => {
                var serverVersion = response.data;

                var localVersion = localStorage.getItem('openLabVersion');


                if (localVersion === null) {
                    localStorage.setItem('openLabVersion', serverVersion);
                    console.log('empty cookie version');
                    window.location.reload(true);
                }
                else {
                    if (serverVersion > localVersion) {
                        localStorage.setItem('openLabVersion', serverVersion);
                        this.deleteCacheAndReloadPage();

                    }
                    else {
                        console.log('current version');
                    }
                }



            })
            .catch(() => {
                console.log("Error getting version");
            });





		const { darkMode,rtlLayout } = this.props;
		this.rtlLayoutOption(rtlLayout);
        this.darkModeHanlder(darkMode);

        var token = await getToken();

        this.setState({
            user: {
                ...this.state.user,
                token: token,
            }
        });


        axios.get('/api/User/GetUserProfile', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                this.setState({
                    user: {
                        ...this.state.user,
                        isAuth: true,
                        sellerWhatsApp: response.data.sellerWhatsApp,
                    }
                });
            })
            .catch(() => {
                this.setState({
                    user: {
                        ...this.state.user,
                        isAuth: false,
                        sellerWhatsApp: '',

                    }
                });
            });
	}

    async deleteCacheAndReloadPage() {
        console.log('new version2');

        if (caches) {

            const names = await caches.keys();
            console.log(names);

            await Promise.all(names.map(name => caches.delete(name)));
        }
        window.location.reload();
    }

	rtlLayoutOption(rtlLayout) {
      if (rtlLayout === true) {
         document.body.classList.add("rtl-layout");
      }
      else {
         document.body.classList.remove("rtl-layout");
      }
	}
	
	darkModeHanlder(darkMode) {
		if (darkMode === true) {
			document.body.classList.add("dark-mode");
		}
		else {
			document.body.classList.remove("dark-mode");
		}
	}
   
   componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
         window.scrollTo(0, 0);
      }
   }

   getUrl(pathname) {
      let pathArray = pathname.split('/');
      return `/${pathArray[1]}` === '/admin-panel' ? true : false;
   }


    logout() {
    
        this.props.history.push("/sign-in");

        this.setState({
            user: {
                ...this.state.user,
                isAuth: false,
                token: '',
            }
        });
    }



    login(data) {

        this.setState({
            user: {
                ...this.state.user,
                isAuth: true,
                token: data.token,
                sellerWhatsApp: data.sellerWhatsApp

            }
        });
    }

    render() {
        const { location } = this.props;
        const { selectedLocale, alertType,theme } = this.props;
        const currentAppLocale = AppLocale[selectedLocale.locale];
        var history = createBrowserHistory();

        const userValue = {
            user: this.state.user,
            logoutUser: this.logout,
            loginUser: this.login,
        }
     

    return (
        <MuiThemeProvider theme={theme}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
                >
                <UserProvider value={userValue}>
                    <Fragment>
         
                        <div className="app-container" style={{ minHeight:'100vh' }}>
                       
                            <HeaderTwo />                                          
                            <Switch>
                            
                                <Route exact path="/" component={AsyncHomePageTwoComponent} />                        

                                <Route path={"/producto/:id"} component={AsyncProductDetail2Component} />
                                <Route path="/cart" component={AsyncCartComponent} />
                                <Route path="/final-receipt" component={AsyncInvoiceComponent} />
                                <Route path="/sobre-nosotros" component={AsyncAboutUSComponent} />
                                <Route path="/terminos-y-condiciones" component={AsyncTermAndConditionComponent} />
                                <Route path="/privacy-policy" component={AsyncPrivacyPolicyComponent} />
                                <Route path="/preguntas-frecuentes" component={AsyncFAQComponent} />

                                <Route path="/sign-in" component={AsyncSignInPageComponent} />

                                <Route path="/sign-up" component={AsyncSignUpSmallComponent} />
                                <Route path="/sign-up-validate/:code" component={AsyncSignUpComponent} />


                                <Route path="/forget-password" component={AsyncForgetPasswordComponent} />
                                <Route path="/account" component={AsyncProfileComponent} />
                                <Route path="/recuperar-usuario/:id" component={AsyncPasswordRecoveryComponent} />
                                <Route path="/clientes-referidos" component={AsyncReferredClientsComponent} />
                                
                                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                                <Route  component={AsyncPageNotFoundComponent} />


                        </Switch>

                            <FooterOne data={footerData} />

                        <SweetAlert
                            success={alertType === 'success'}
                            error={alertType === 'error'}
                            title=''
                            confirmBtnText="Ok"
                            confirmBtnBsStyle="warning"
                            className="iron-alert-box"
                            show={this.props.showAlert}
                            onConfirm={this.props.hideAlert}
                            onCancel={this.props.hideAlert}
                            closeOnClickOutside
                        >
                            {this.props.alertMessage}
                        </SweetAlert>

                        </div>
                  
                    <ThemeOptions url={this.getUrl(location.pathname)} />
                        </Fragment>
                </UserProvider >
            </IntlProvider>
        </MuiThemeProvider>
    )
   }
}

// map state to props
const mapStateToProps = ({ appSettings }) => {
   const { showAlert, alertMessage, selectedLocale, alertType, theme, darkMode, rtlLayout } = appSettings;
   return { showAlert, alertMessage, selectedLocale, alertType,theme,darkMode,rtlLayout };
}

export default connect(mapStateToProps, {
   hideAlert
})(App);