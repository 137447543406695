/**
 * footer menu data
 */
const footerData = {
    about: [
        //{
        //    "path": "/sobre-nosotros",
        //    "menu_title": "footer.aboutUs"
        //},
        {
            "path": "/terminos-y-condiciones",
            "menu_title": "footer.termsAndCondition"
        },
        {
            "path": "/privacy-policy",
            "menu_title": "footer.PrivacyPolicy"
        },
        {
            "path": "/preguntas-frecuentes",
            "menu_title": "footer.Faq"
        },
        //{
        //    "path": "/contact-us",
        //    "menu_title": "footer.contactUs"
        //}
    ],
    session: [
        //{
        //    "path": "/sign-in",
        //    "menu_title": "footer.signIn"
        //},
        //{
        //    "path": "/sign-up",
        //    "menu_title": "footer.register"
        //},
        //{
        //    "path": "/forget-password",
        //    "menu_title": "footer.forgetPassword"
        //},
        //{
        //    "path": "/thank-you",
        //    "menu_title": "footer.thankYou"
        //}
    ],

    social: [
        {
            "path": "https://www.facebook.com/",
            "menu_title": "footer.facebook"
        },
        //{
        //    "path": "https://twitter.com",
        //    "menu_title": "footer.twitter"
        //},
        //{
        //    "path": "https://www.youtube.com",
        //    "menu_title": "footer.youtube"
        //},
        {
            "path": "https://www.instagram.com/",
            "menu_title": "footer.instagram"
        },
      
    ]
}
export default footerData;