import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppConfig from '../../../constants/AppConfig';


const ContentLoader = () => (
   <div className="iron-progress-bar d-flex justify-content-center align-items-center">
        {/*<CircularProgress size={70} thickness={1.5} />*/}

        <div style={{ width: '20%', textAlign:'center' }}>
            <img src={AppConfig.AppLogo} alt="header-logo-openlab" width="150px" />
            <br />
            <br />
            <br />
            <br />

            <LinearProgress />
            <p style={{ color: '#999', marginTop:'20px' }}>
                Espere por favor...
            </p>


       </div>
   </div>
);

export default ContentLoader;