/**
 * site footer one component
 */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
// intl message
import IntlMessages from '../../../util/IntlMessages';

//App Config
import AppConfig from '../../../constants/AppConfig';
import { Typography } from '@material-ui/core';
const localVersion = localStorage.getItem('openLabVersion');


const footerStyle = {
    backgroundColor: "purple",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%"
};

const phantomStyle = {
    //clear: 'both',
    //position: 'relative',
    //height: '130px',
    //marginTop: '-130px',
};


export default function FooterOne(props) {

    const { about } = props.data;



   return (
       <footer className="iron-footer-v1" style={phantomStyle}>
           <div className="iron-footer-top">
               <div className="container" >

                   <Grid container spacing={4}>

                       <Grid item xs={12} md={4} lg={4} xl={4}>
                           <div>
                               <div className="footer-widget-title mb-30">
                                   <h6>Nosotros</h6>
                               </div>
                               <List component="nav" className="">
                                   {
                                       about.map((session, key) => {
                                           return (
                                               <li key={key}  style={{padding: '0 0 0.3125rem 0', marginBottom: '0.625rem'}}>
                                                   <Link className="afooter" to={session.path}>
                                                       <IntlMessages id={session.menu_title} />
                                                   </Link>
                                               </li>
                                           )
                                       })
                                   }
                               </List>
                           </div>
                               
                       </Grid>
                       <Grid item xs={12} md={4} lg={4} xl={4}>
                 
                               <div>
                                   <div className="footer-widget-title mb-30">
                                       <h6>Redes sociales</h6>
                                   </div>
                                   <List component="nav" className="">
                                        <li   style={{ padding: '0 0 0.3125rem 0', marginBottom: '0.625rem' }}>
                                       <a className="afooter" href={" https://www.facebook.com/OpenLab24h-109560804602298"} target="blank">
                                               Facebook
                                           </a>
                                       </li>
                                       <li syle={{ padding: '0 0 0.3125rem 0', marginBottom: '0.625rem' }}>
                                       <a className="afooter" href={"https://www.instagram.com/openlab.ok/"} target="blank">
                                               Instagram
                                           </a>
                                       </li>
                                   </List>
                           </div>
                       
                       </Grid>             

                       <Grid item xs={12} md={4} lg={4} xl={4}>
                           <a href={"http://www.biosintex.com.ar"} target="blank">
                               <img src={require("../../../assets/images/biosintexFooter.png")} alt="Biosintex" />
                               </a>
                        </Grid>
                   </Grid>

                   <hr style={{margin:'16px 0px 16px 0px'}} />

                   <Grid container alignItems="center" spacing={4} justify="flex-end" >
                       <Grid item xs={12} md={3} lg={3} xl={3}>                           
                       </Grid>
                       <Grid item xs={12} md={6} lg={6} xl={6}>
                           <center>
                               Todos los derechos reservados | Biosintex24hs.com  {"v 1." + localVersion + ".10"}
                               </center>
                       </Grid>

                       <Grid item xs={12} md={3} lg={3}>
                           <div style={{ float: 'rigth' }}>

                               <a href={"http://www.cretai.com"} target="blank">
                                   <img src={require("../../../assets/images/cretai.png")} alt="Cretai" style={{ width: '45px', float: 'right' }} />
                                   <br />
                                   <br />
                                   <p style={{ float: 'right' }}>Powered by Cretai</p>
                                </a>
                               
                               </div>
                       </Grid>
                   </Grid>
            </div>
           </div>

          

      </footer>
   )
}
