/**
 * AsyncRoutes
 * Code Splitting Component / Server Side Rendering
 */
import React from 'react';
import Loadable from 'react-loadable';

//app loader
import ContentLoader from '../components/global/loaders/ContentLoader';




// Home
const AsyncHomePageTwoComponent = Loadable({
   loader: () => import('../routes/home-two'),
   loading: () => <ContentLoader />,
});

// Home





// about us
const AsyncAboutUSComponent = Loadable({
   loader: () => import('../routes/about-us'),
   loading: () => <ContentLoader />,
});

// cart
const AsyncCartComponent = Loadable({
   loader: () => import('../routes/cart'),
   loading: () => <ContentLoader />,
});





// Faq
const AsyncFAQComponent = Loadable({
   loader: () => import('../routes/faq'),
   loading: () => <ContentLoader />,
});

// page404
const AsyncPageNotFoundComponent = Loadable({
   loader: () => import('../routes/page-404'),
   loading: () => <ContentLoader />,
});



// privacy policy
const AsyncPrivacyPolicyComponent = Loadable({
   loader: () => import('../routes/privacy-policy'),
   loading: () => <ContentLoader />,
});

// Terms and Condition
const AsyncTermAndConditionComponent = Loadable({
   loader: () => import('../routes/term-and-condition'),
   loading: () => <ContentLoader />,
});

// product detail
const AsyncProductDetailComponent = Loadable({
   loader: () => import('../routes/products'),
   loading: () => <ContentLoader />,
});


// product detail2
const AsyncProductDetail2Component = Loadable({
    loader: () => import('../routes/product'),
    loading: () => <ContentLoader />,
});

// invoice
const AsyncInvoiceComponent = Loadable({
   loader: () => import('../routes/check-out/final-receipt'),
   loading: () => <ContentLoader />,
});



const AsyncProfileComponent = Loadable({
    loader: () => import('../routes/profile'),
    loading: () => <ContentLoader />,
});


// SignIn
const AsyncSignInPageComponent = Loadable({
   loader: () => import('../routes/session/sign-in'),
   loading: () => <ContentLoader />,
});

// Register
const AsyncSignUpComponent = Loadable({
   loader: () => import('../routes/session/sign-up'),
   loading: () => <ContentLoader />,
});

// Register small
const AsyncSignUpSmallComponent = Loadable({
    loader: () => import('../routes/session/sign-up/signUpSmall'),
    loading: () => <ContentLoader />,
});

// ForgetPassword
const AsyncForgetPasswordComponent = Loadable({
   loader: () => import('../routes/session/forget-password'),
   loading: () => <ContentLoader />,
});


const AsyncPasswordRecoveryComponent = Loadable({
    loader: () => import('../routes/session/recuperar-usuario'),
    loading: () => <ContentLoader />,
});





// referred-clients
const AsyncReferredClientsComponent = Loadable({
    loader: () => import('../routes/referred-clients/'),
    loading: () => <ContentLoader />,
});




export {


    AsyncHomePageTwoComponent,






   AsyncCartComponent,

   AsyncInvoiceComponent,



   AsyncAboutUSComponent,   
   AsyncFAQComponent,
   

   AsyncPrivacyPolicyComponent,
   AsyncTermAndConditionComponent,
   AsyncProductDetailComponent,
    AsyncProductDetail2Component,

   
   AsyncSignInPageComponent,
    AsyncSignUpComponent,
    AsyncSignUpSmallComponent,
    AsyncForgetPasswordComponent,
    AsyncPasswordRecoveryComponent,

    AsyncPageNotFoundComponent,
    AsyncProfileComponent,
    AsyncReferredClientsComponent
}